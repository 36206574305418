.container :global(.recharts-cartesian-grid-vertical) line,
.container :global(.recharts-cartesian-grid-horizontal) line {
  stroke: var(--color-palette-4);
  opacity: 0.2;
}

.container :global(.recharts-cartesian-axis) line {
  stroke: #9df0ac;
}

.container :global(.recharts-cartesian-axis-tick-value),
.container :global(.recharts-polar-radius-axis-tick-value) {
  fill: var(--color-palette-3);
}

.container :global(.recharts-scatter-symbol) path {

}

.container :global(.recharts-legend-wrapper) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container :global(.recharts-cartesian-axis-tick-value) {
  font-weight: 500;
  font-family: var(--global-font-family);
}

.container :global(.recharts-surface) {
  overflow: visible;
}