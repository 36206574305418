.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--global-font-family);
  height: calc(100vh - 14em);
}

.span {
  border-bottom: 3px solid var(--color-palette-1);
  cursor: pointer;
  font-style: italic;
}

.chart {
  height: 20em;
  width: 50em;
  margin-top: 7em;
  margin-bottom: 6em;
}

.metrics {
  color: var(--color-palette-3);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
}

.metrics_count {
  font-size: 6em;
}

.metrics_label {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05em;
}

.selector {
  margin-top: 0.5em;
  text-transform: uppercase;
  color: var(--color-palette-3);
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

.selector_button {
  background-color: transparent;
  border: none;
}

.arrow_left,
.arrow_right {
  height: 1em;
  z-index: 0;
}

.arrow_left {
  transform: rotate(180deg);
}

.selector_button {
  margin: 0 1em;
  cursor: pointer;
}

.selector_button:disabled {
  opacity: 0.3;
}

.button {
  margin-top: 3em;
}

.modal {
  z-index: 10000;
}

.modal_body {
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legend {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 5em auto 0 auto;
}

.legend_item {
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legend_text {
  margin-left: 0.5em;
  color: var(--color-palette-3);
  font-family: var(--global-font-family);
  font-weight: 300;
}

@media (max-width: 1000px) {
  
  .container {
    height: auto;
    margin-top: 6em;
  }

  .selector {
    margin-top: 1.5em;
  }

  .metrics_count {
    font-size: 3em;
  }

  .button {
    font-size: 1em;
    margin-top: 4em;
  }

  .chart {
    width: 70%;
    height: 20em;
  }
}

@media (max-width: 768px) {

  .chart {
    width: 100%;
    height: 20em;
    margin-top: 4em;
  }
}